<template>
	<main :data-test="$route.path">
		<header>
			<div class="relative grid min-h-495 tablet:min-h-720 tabletWide:min-h-696 desktop:min-h-618">
				<div class="tablet:mx-48 desktop:mx-56">
					<div class="pt-16 tablet:pt-24 pb-32 tablet:pb-24 px-16 tablet:px-48 desktop:px-56 max-w-375 tablet:max-w-none desktop:mx-auto desktop:max-w-1440 flex flex-col h-full space-y-24">
						<div class=" flex-1 flex flex-col justify-end tablet:justify-center space-y-24 tablet:space-y-16 tabletWide:space-y-32">
							<p class="text-white font-bold text-40 tablet:text-64" data-test="title">
								<inlineMarkdown v-once class="text-40 tablet:text-64" :markdown="pageContent.pageHeader.title" />
							</p>

							<h1>
								<a v-if="!!pageContent.pageHeader.headlineLink" :href="pageContent.pageHeader.headlineLink" class="hover:opacity-90 text-white text-18 tablet:text-22 drop-shadow-xl antialiased">
									<inlineMarkdown v-once :markdown="pageContent.pageHeader.headline" />
								</a>
								<inlineMarkdown v-else v-once :markdown="pageContent.pageHeader.headline" class="text-white text-18 tablet:text-22 drop-shadow-xl antialiased" />
							</h1>

							<div class="flex items-center tabletWide:items-start desktop:items-center tabletWide:flex-col desktop:flex-row tablet:space-x-32 tabletWide:space-x-16 tabletWide:space-y-32 desktop:space-y-0 ">
								<formItinerary theme="ghost" :enable-promo-code="true" class="bg-white/40 rounded-GO p-16 w-full tablet:w-8/12 tabletWide:w-full desktop:w-10/12 desktop:p-24 desktopWide:w-9/12" />

								<endorsementsHeader />
							</div>
						</div>
					</div>
				</div>

				<figure class="absolute inset-0 -z-10 rounded-GO overflow-hidden tablet:ml-48 desktop:ml-56">
					<LazyHydrate never>
						<goPicture v-once v-bind="pageContent.pageHeader.picture" :enable-placeholder="false" class="h-full" />
					</LazyHydrate>
				</figure>

				<div class="absolute inset-0 -z-10 rounded-GO overflow-hidden tablet:ml-48 desktop:ml-56 bg-goPageHeaderGradientMobile tablet:bg-goPageHeaderGradientTablet tabletWide:bg-goPageHeaderGradientTabletWide desktop:bg-goPageHeaderGradientDesktop desktopWide:bg-goPageHeaderGradientDesktopWide"></div>
			</div>
		</header>

		<div class="py-16 tablet:py-48 desktop:py-56 space-y-56 tabletWide:space-y-112">
			<LazyHydrate when-visible>
				<layoutSection v-if="$store.state.reviews.reviews.length">
					<div class="p-16 tablet:p-0 grid grid-cols-12 items-center tablet:items-stretch tabletWide:gap-16">
						<allReviews class=" col-span-8 tablet:col-span-10 tablet:col-span-9 desktop:col-span-6"/>
						<allEndorsements class=" h-full border-black/10 border-l col-span-4 tablet:col-span-2 tablet:col-span-3 desktop:col-span-6"/>
					</div>
				</layoutSection>
			</LazyHydrate>
			<LazyHydrate when-visible>
				<layoutSection class="p-16 group bg-goPink hover:bg-goPinkDark transition-colors rounded-GO tablet:rounded-tr-0 cursor-pointer">
					<subscribeBanner />
				</layoutSection>
			</LazyHydrate>
			<LazyHydrate when-visible>
				<layoutSection type="post3">
					<template #main>
						<layoutPost type="left">
							<header>
								<h2 class="font-bold text-32 tablet:text-40 desktop:text-48">NZ Car Rental <span class="whitespace-nowrap">Made Easy</span></h2>
							</header>

							<p class="text-18 desktop:text-22">We make renting a car in New Zealand ridiculously simple. Apart from GO Rentals being one of New Zealand’s largest independent car rental companies, we also have brand new cars of all shapes and sizes to best suit the holiday you’ve got planned.</p>
							<p>With a fleet of some of the best rental cars in NZ, pop onto the vehicles page and check out our huge range of modern rental cars, including the Tesla Model 3 and Model Y, Ford Everest Titanium, Mitsubishi Pajero Sport, and Toyota CHR.</p>
							<p>And don’t worry, its not all about the techy car details – you can also explore some of our fantastic trip planning resources and discounts from our local experts.</p>
							<p>Once you’ve sorted out where you’re going and what you want to see along the way, booking your rental car is as simple as clicking a button.</p>
							<p>Don’t worry if you’re not built for the internet though as we have a helpful team of road savvy experts happy to chat on the phone and answer your questions or take care of the booking for you. GO Rentals - making car hire in New Zealand simple for over 20 years.</p>

							<footer>
								<nuxt-link to="/vehicles/" class="button button-goPink button-normal space-x-12 group">
									<span>Search cars</span>
									<svg-icon name="arrow-forward" class="iconSize-24 opacity-50 group-hover:translate-x-1" />
								</nuxt-link>
							</footer>
						</layoutPost>
					</template>

					<template #aside_top>
						<goPicture
							class="w-full h-full overflow-hidden rounded-tr-16"
							alt="Image of woman laughing, sitting on the front seat of car parked in front of dense bush"
							:mobile="{ src: '/images/Home_Fair_Rentals.jpg', width: 375, height: 250 }"
							:tablet="{ src: '/images/Home_Fair_Rentals.jpg', width: 456, height: 304 }"
							:tabletWide="{ src: '/images/Home_Fair_Rentals.jpg', width: 220, height: 330 }"
							:desktop="{ src: '/images/Home_Fair_Rentals.jpg', width: 576, height: 384 }"
							:desktopWide="{ src: '/images/Home_Fair_Rentals.jpg', width: 656, height: 438 }"
						/>
					</template>

					<template #aside_left>
						<goPicture
							class="w-full h-full overflow-hidden rounded-bl-16"
							alt="Close up image of a gear stick in a modern rental car"
							:mobile="{ src: '/images/Home_Quality_Vehicle_1.jpg', width: 184, height: 276 }"
							:tablet="{ src: '/images/Home_Quality_Vehicle_1.jpg', width: 328, height: 492 }"
							:tabletWide="{ src: '/images/Home_Quality_Vehicle_1.jpg', width: 220, height: 330 }"
							:desktop="{ src: '/images/Home_Quality_Vehicle_1.jpg', width: 280, height: 420 }"
							:desktopWide="{ src: '/images/Home_Quality_Vehicle_1.jpg', width: 320, height: 480 }"
						/>
					</template>

					<template #aside_right>
						<goPicture
							class="w-full h-full"
							alt="Close up image of a new Hyundai Santa Fe rental car with pink GO Rentals plates"
							:mobile="{ src: '/images/Home_Quality_Vehicle_2.jpg', width: 184, height: 276 }"
							:tablet="{ src: '/images/Home_Quality_Vehicle_2.jpg', width: 328, height: 492 }"
							:tabletWide="{ src: '/images/Home_Quality_Vehicle_2.jpg', width: 220, height: 330 }"
							:desktop="{ src: '/images/Home_Quality_Vehicle_2.jpg', width: 280, height: 420 }"
							:desktopWide="{ src: '/images/Home_Quality_Vehicle_2.jpg', width: 320, height: 480 }"
						/>
					</template>
				</layoutSection>
			</LazyHydrate>

			<LazyHydrate when-visible>
				<layoutSection type="post-reversed">
					<template #main>
						<layoutPost type="center">
							<header>
								<h2 class="font-bold text-32 tablet:text-40 desktop:text-48">Sustainable <span class="whitespace-nowrap">car rental</span> in <span class="whitespace-nowrap">New Zealand</span></h2>
							</header>

							<p class="text-18 desktop:text-22">We’ve partnered with CarbonClick to help you effortlessly reduce the environmental impact of your travel here in New Zealand, contributing to a more sustainable tourism sector in Aotearoa. Find out more about the projects offsetting your carbon emissions when you rent a car with GO. We have also achieved a Toitū recertification and are investing carbon credits in support of the highest quality carbon offsetting projects both in New Zealand and internationally. </p>

							<footer>
								<nuxt-link to="/go-zero/" class="button button-goBlueGhost button-normal">
									Find out more
								</nuxt-link>
							</footer>
						</layoutPost>
					</template>

					<template #aside>
						<goPicture
							class="w-full h-full"
							alt="Image of a lake, mountains and road in the background with a car in the foreground"
							:mobile="{ src: '/images/carbonClick-home.jpg', width: 375, height: 250 }"
							:tablet="{ src: '/images/carbonClick-home.jpg', width: 456, height: 304 }"
							:tabletWide="{ src: '/images/carbonClick-home.jpg', width: 220, height: 330 }"
							:desktop="{ src: '/images/carbonClick-home.jpg', width: 576, height: 384 }"
							:desktopWide="{ src: '/images/carbonClick-home.jpg', width: 656, height: 438 }"
						/>
					</template>
				</layoutSection>
			</LazyHydrate>

			<LazyHydrate when-visible>
				<layoutSection type="post">
					<template #main>
						<layoutPost type="left">
							<header>
								<h2 class="font-bold text-32 tablet:text-40 desktop:text-48">Our commitment <span class="whitespace-nowrap">to the Tiaki Promise</span></h2>
							</header>

							<p class="text-18 desktop:text-22">We’re on a mission at GO Rentals to make travel more sustainable in New Zealand for Kiwis and international visitors. As well as our fleet of zero emission electric vehicles and eco-friendly hybrid vehicles, we are a Toitū certified business, plus we’re also integrating Tiaki across our business.</p>

							<p>Tiaki means to care for people and place. We hope you'll join us on our mission to look after Aotearoa New Zealand, keeping it safe and beautiful for the next generation of travellers.</p>

							<footer>
								<nuxt-link to="/tiaki-promise/" class="button button-goBlueGhost button-normal">
									Find out more
								</nuxt-link>
							</footer>
						</layoutPost>
					</template>

					<template #aside>
						<goPicture
							class="w-full h-full rounded-tr-16"
							alt="Image of a lake, mountains and road in the background with a car in the foreground"
							:mobile="{ src: '/images/tiaki-home.jpg', width: 375, height: 250 }"
							:tablet="{ src: '/images/tiaki-home.jpg', width: 456, height: 304 }"
							:tabletWide="{ src: '/images/tiaki-home.jpg', width: 220, height: 330 }"
							:desktop="{ src: '/images/tiaki-home.jpg', width: 576, height: 384 }"
							:desktopWide="{ src: '/images/tiaki-home.jpg', width: 656, height: 438 }"
						/>
					</template>
				</layoutSection>
			</LazyHydrate>

			<LazyHydrate when-visible>
				<layoutSection>
					<layoutPost type="wider" class="tablet:p-0">
						<reasonsToGoWithUs class="desktop:w-8/12" />
					</layoutPost>
				</layoutSection>
			</LazyHydrate>

			<LazyHydrate when-visible>
				<layoutSection class="bg-goSkyBright bg-nzMapBackground tabletWide:bg-nzMapBackground-reversed rounded-GO tablet:rounded-tr-0">
					<goAllAcrossNZ/>
				</layoutSection>
			</LazyHydrate>

			<LazyHydrate when-visible>
				<layoutSection type="post-reversed">
					<template #main>
						<layoutPost type="center">
							<header>
								<h2 class="font-bold text-32 tablet:text-40 desktop:text-48">Ridiculously simple <span class="whitespace-nowrap">pick-up</span> & <span class="whitespace-nowrap">drop-off</span> of our <span class="whitespace-nowrap">rental cars</span></h2>
							</header>

							<p class="text-18 desktop:text-22">With our GO App, you'll be on the road (and on your holiday) faster.</p>
							<p>Keep all your car rental info close at hand with no need to trawl through your emails. Our app icon is bright pink so brighten up your home screen and make your car rental experience in New Zealand super-simple.</p>

							<footer>
								<nuxt-link to="/app/" class="button button-goBlueGhost button-normal">
									Download App
								</nuxt-link>
							</footer>
						</layoutPost>
					</template>

					<template #aside>
						<figure class="h-full flex items-center ">
							<videoYouTube class="w-full" v-bind="{
								image: { mobile: { src: '/images/Simple_PU_DO.jpg' }, alt: 'Image of a GO Rentals branch showing its self-service kiosks' },
								title: 'The GO Experience',
								src: 'https://www.youtube.com/embed/KjChqxbIHoY',
								duration: '1:58',
								ratio: '3:2',
							}" />
						</figure>
					</template>
				</layoutSection>
			</LazyHydrate>

			<LazyHydrate when-visible>
				<layoutSection type="post2">
					<template #main>
						<layoutPost type="left">
							<header>
								<h2 class="font-bold text-32 tablet:text-40 desktop:text-48">Outstanding <br class="hidden tablet:unset" />on-road support</h2>
							</header>

							<p class="text-18 desktop:text-22">24/7 roadside assistance, we’ve got your back — so you can make the most of your time in New Zealand. Tick rental car off your list of things to do when you book with GO - we’ve got you covered every step of the way.</p>

							<footer>
								<nuxt-Link to="/about-us/why-choose-go/" class="button button-goBlueGhost button-normal">
									Why GO with us?
								</nuxt-Link>
							</footer>
						</layoutPost>
					</template>

					<template #aside_left>
						<goPicture
							class="w-full h-full"
							alt="Image of Lake Pukaki in the South Island with the road in the foreground and mountains in the background"
							:mobile="{ src: '/images/Home_Road_Support_1.jpg', width: 184, height: 276 }"
							:tablet="{ src: '/images/Home_Road_Support_1.jpg', width: 328, height: 492 }"
							:tabletWide="{ src: '/images/Home_Road_Support_1.jpg', width: 220, height: 330 }"
							:desktop="{ src: '/images/Home_Road_Support_1.jpg', width: 280, height: 420 }"
							:desktopWide="{ src: '/images/Home_Road_Support_1.jpg', width: 320, height: 480 }"
						/>
					</template>

					<template #aside_right>
						<goPicture
							class="w-full h-full overflow-hidden rounded-GO"
							alt="Image of an older couple sitting on a bench with a fire in the foreground and lake in the background"
							:mobile="{ src: '/images/Home_Road_Support_2.jpg', width: 184, height: 276 }"
							:tablet="{ src: '/images/Home_Road_Support_2.jpg', width: 328, height: 492 }"
							:tabletWide="{ src: '/images/Home_Road_Support_2.jpg', width: 220, height: 330 }"
							:desktop="{ src: '/images/Home_Road_Support_2.jpg', width: 280, height: 420 }"
							:desktopWide="{ src: '/images/Home_Road_Support_2.jpg', width: 320, height: 480 }"
						/>
					</template>

					<template #below>
						<div class="col-span-12 p-16 tablet:p-0 tablet:pointer-events-none tablet:z-10 tablet:absolute tablet:top-48 tablet:left-48 desktop:top-56 tabletWide:left-5/12">
							<feedback
								class="tablet:shadow-sm bg-goGrayBrighter tablet:bg-white/80 tablet:w-6/12 tabletWide:w-7/12 desktop:w-5/12 "
								text="GO Rentals are AWESOME! The car was fantastic, safe and didn’t miss a beat. From the moment we picked up the car the staff were so helpful and gave us lots of local knowledge tips. 10/10 thanks Go rentals!"
								avatar="/images/Home_Tegan.jpg"
								alt="Image of Tegan from Australia next to review of GO Rentals "
								name="Tegan"
								detail="Australia"
								/>
						</div>
					</template>
				</layoutSection>
			</LazyHydrate>

			<LazyHydrate when-visible>
				<layoutSection type="post3-reversed">
					<template #main>
						<div class="bg-goSkyBright tabletWide:rounded-tr-16 tabletWide:h-full tabletWide:py-16">
							<layoutPost type="center">
								<img src="/images/logo-go.svg" decoding="async" alt="Go Snap Happy Logo" width="75" height="32" />

								<header>
									<h2 class="font-bold text-32 tablet:text-40 desktop:text-48">GO Snap Happy</h2>
								</header>

								<p class="text-18 desktop:text-22">At GO Rentals, we love being a part of your travel journey and helping you make wonderful memories.<br>Snap a picture of your travels around New Zealand, and be in to win up to $500 of the cost of your rental back.</p>

								<footer>
									<nuxt-Link to="/go-snap-happy/" class="button button-goBlueGhost button-normal">
										Find out more
									</nuxt-Link>
								</footer>
							</layoutPost>
						</div>
					</template>

					<template #aside_top>
						<goPicture
							class="w-full h-full overflow-hidden rounded-tr-16 tabletWide:rounded-0"
							alt="Image of the Cape Reinga Lighthouse on a sunny day with the world destination sign in the foreground"
							:mobile="{ src: '/images/Home_GO_Snap_Happy_0.png', width: 375, height: 250 }"
							:tablet="{ src: '/images/Home_GO_Snap_Happy_0.png', width: 456, height: 304 }"
							:tabletWide="{ src: '/images/Home_GO_Snap_Happy_0.png', width: 220, height: 330 }"
							:desktop="{ src: '/images/Home_GO_Snap_Happy_0.png', width: 576, height: 384 }"
							:desktopWide="{ src: '/images/Home_GO_Snap_Happy_0.png', width: 656, height: 438 }"
						/>
					</template>

					<template #aside_left>
						<goPicture
							class="w-full h-full overflow-hidden rounded-bl-16"
							alt="Image of a couple sitting in the back of their 4x4 rental car at dusk"
							:mobile="{ src: '/images/Home_GO_Snap_Happy_1.png', width: 184, height: 276 }"
							:tablet="{ src: '/images/Home_GO_Snap_Happy_1.png', width: 328, height: 492 }"
							:tabletWide="{ src: '/images/Home_GO_Snap_Happy_1.png', width: 220, height: 330 }"
							:desktop="{ src: '/images/Home_GO_Snap_Happy_1.png', width: 280, height: 420 }"
							:desktopWide="{ src: '/images/Home_GO_Snap_Happy_1.png', width: 320, height: 480 }"
						/>
					</template>

					<template #aside_right>
						<goPicture
							class="w-full h-full"
							alt="Image of two guys unloading their bags from the back of their rental car"
							:mobile="{ src: '/images/Home_GO_Snap_Happy_2.png', width: 184, height: 276 }"
							:tablet="{ src: '/images/Home_GO_Snap_Happy_2.png', width: 328, height: 492 }"
							:tabletWide="{ src: '/images/Home_GO_Snap_Happy_2.png', width: 220, height: 330 }"
							:desktop="{ src: '/images/Home_GO_Snap_Happy_2.png', width: 280, height: 420 }"
							:desktopWide="{ src: '/images/Home_GO_Snap_Happy_2.png', width: 320, height: 480 }"
						/>
					</template>
				</layoutSection>
			</LazyHydrate>

			<LazyHydrate when-visible>
				<layoutSection type="post">
					<template #main>
						<layoutPost type="left">
							<header>
								<h2 class="font-bold text-32 tablet:text-40 desktop:text-48">About GO Rentals</h2>
							</header>

							<p class="text-18 desktop:text-22">Here at GO we’re all about helping our customers turn their daydream into reality.</p>
							<p>Whether it’s a weekend away or the adventure of a lifetime, we’re excited to be part of your trip.</p>
							<p>We'll take care of the important stuff and point out the fun things you can do while on the road - freeing you up to really enjoy the journey.</p>
							<p>GO love every moment with the perfect rental car for your journey.</p>
						</layoutPost>
					</template>

					<template #aside>
						<goPicture
							class="w-full h-full"
							alt="Image of a Toyota Rav4 at dusk parked up next to a tent with mountains in background"
							:mobile="{ src: '/images/About_Team.jpg', width: 375, height: 250 }"
							:tablet="{ src: '/images/About_Team.jpg', width: 456, height: 304 }"
							:tabletWide="{ src: '/images/About_Team.jpg', width: 220, height: 330 }"
							:desktop="{ src: '/images/About_Team.jpg', width: 576, height: 384 }"
							:desktopWide="{ src: '/images/About_Team.jpg', width: 656, height: 438 }"
						/>
					</template>
				</layoutSection>
			</LazyHydrate>

			<LazyHydrate when-visible>
				<layoutSection class="p-16 tablet:p-0">
					<faqList :faqs="pageContent.faqs" />
				</layoutSection>
			</LazyHydrate>

			<LazyHydrate when-visible>
				<layoutSection>
					<layoutPost type="wider" class="tablet:p-0 grid grid-cols-12 gap-y-32 tablet:gap-16 tabletWide:space-y-0">
						<article class="col-span-12 tablet:col-span-8 tabletWide:col-span-6 desktop:col-span-4 space-y-32">
							<svg-icon name="sun-outline" class="iconSize-96 text-goPink" />

							<header>
								<h2 class="font-bold text-20 tablet:text-24">We’re all about YOUR experience</h2>
							</header>

							<div class="space-y-16">
								<p>Let’s be honest, in the greater scheme of holiday planning, you’re not really that excited about booking your hire car.</p>
								<p>This year, we are super-excited to be welcoming back visitors from Australia and the rest of the world to New Zealand and we look forward to helping you to create amazing memories from your time here in New Zealand.</p>
								<p>What is fun is planning the holiday and being free to really enjoy the journey.</p>
								<p>Visitor experience is extremely important to us at GO. From booking online via our website, to pick-up and drop-off, we’ve worked hard to make every part of your booking journey reflect that easy holiday feeling.</p>
								<p>We’ve backed that up by winning cool stuff too - In 2018, we were thrilled to be announced winner of the Visitor Experience Award at the New Zealand Tourism Awards - for a car rental company, that’s a pretty big deal!</p>
								<p>We have received a perfect 5/5 stars across all categories and won the 2023 Canstar Blue Most Satisfied Customers Award in the Hire Cars category. We were also winners in this category in 2018, 2020, 2022 & 2023. Couple this with our amazing customer service and you’ve got a winning combination when it comes to renting a car in New Zealand.</p>
								<p>But don’t just take our word for it - check out the reviews of those who have come before you and then come and experience GO Rentals for yourself.</p>
							</div>
						</article>

						<article class="col-span-12 tablet:col-span-8 tabletWide:col-span-6 desktop:col-span-4 space-y-32">
							<svg-icon name="hills-outline" class="iconSize-96 text-goPink" />

							<header>
								<h2 class="font-bold text-20 tablet:text-24">Competitive New Zealand <span class="whitespace-nowrap">Rental Car Rates</span></h2>
							</header>

							<div class="space-y-16">
								<p>Everyone says they have the best prices and with the internet offering comparisons of all the rental car options and prices we understand that you’ve done your research.</p>
								<p>So you know you can trust us when we tell you that here at GO, we’re proud to offer some of New Zealand’s most competitive hire car rates. Whether you’re after one of our super spacious 4x4s or maybe something a bit more economical, we’ve got some great deals.</p>
								<p>When you hire a car with GO, you’re guaranteed our excellent customer service from the first contact right through to dropping off your keys, regardless of whether you want to do that by chatting with our excellent team in person or prefer to go digital on the app. Car rental in NZ doesn’t need to be complicated, so we’ve made things as simple as we can so you can spend more time planning your trip of a lifetime.</p>
								<p>Oh and don’t forget that with GO you’ll get unlimited kilometres, free basic car rental insurance and 24/7 AA roadside assistance. It really does pay to rent with GO.</p>
							</div>
						</article>
					</layoutPost>
				</layoutSection>
			</LazyHydrate>
		</div>
	</main>
</template>

<script>
import layoutSection from '~/components/layoutSection.vue'
import layoutPost from '~/components/layoutPost.vue'
import inlineMarkdown from "~/components/inlineMarkdown.vue"
import goAllAcrossNZ from "~/components/goAllAcrossNZ.vue"
import feedback from "~/components/feedback.vue"
import reasonsToGoWithUs from "~/components/reasonsToGoWithUs.vue"
import endorsementsHeader from "~/components/endorsementsHeader.vue"
import { getSchemaFaqPage } from '~/assets/scripts/utilities/getStructuredData'
import { getPageMetaData } from '~/assets/scripts/utilities/getPageMetaData'

export default {
	components: {
		layoutSection,
		layoutPost,
		inlineMarkdown,
		goAllAcrossNZ,
		feedback,
		reasonsToGoWithUs,
		endorsementsHeader,
		faqList: () => import(/* webpackChunkName: "faqList" */ "~/components/faqList.vue"),
		formItinerary: () => import(/* webpackChunkName: "formItinerary" */ "~/components/formItinerary.vue"),
		allReviews: () => import(/* webpackChunkName: "allReviews" */ "~/components/allReviews.vue"),
		allEndorsements: () => import(/* webpackChunkName: "allEndorsements" */ "~/components/allEndorsements.vue"),
		videoYouTube: () => import(/* webpackChunkName: "videoYouTube" */ "~/components/videoYouTube.vue"),
		subscribeBanner: () => import(/* webpackChunkName: "subscribeBanner" */"~/components/subscribeBanner.vue"),
	},
	metaInfo() {
		const { picture } = this.pageContent.pageHeader

		return {
			title: this.pageMeta.title,
			meta: [
				{
					vmid: "description",
					name: "description",
					content: this.pageMeta.description,
				},
				{ vmid: "ogtitle", property: "og:title", content: this.pageMeta.title },
				{ vmid: "ogurl", property: "og:url", content: "https://www.gorentals.co.nz/" },
			],
			link: [
				{ rel: "home", href: "https://www.gorentals.co.nz/" },
				{ rel: "canonical", href: "https://www.gorentals.co.nz/" },
				{ rel: "preload",	href: this.$cloudFlare.getImage(picture.desktopWide.src,	{ width: picture.desktopWide.width,		height: picture.desktopWide.height }),		as: 'image', media: `(min-width: 100em)`,	fetchpriority: "high" },
				{ rel: "preload",	href: this.$cloudFlare.getImage(picture.desktop.src, 		{ width: picture.desktop.width,			height: picture.desktop.height }),			as: 'image', media: `(min-width: 80em)		and (max-width: ${((100   * 16) - 1) / 16}em)`,	fetchpriority: "high" },
				{ rel: "preload",	href: this.$cloudFlare.getImage(picture.tabletWide.src, 	{ width: picture.tabletWide.width,		height: picture.tabletWide.height }),		as: 'image', media: `(min-width: 62.5em)	and (max-width: ${((80	  * 16) - 1) / 16}em)`,	fetchpriority: "high" },
				{ rel: "preload",	href: this.$cloudFlare.getImage(picture.tablet.src,			{ width: picture.tablet.width,			height: picture.tablet.height }),			as: 'image', media: `(min-width: 43.75em)	and (max-width: ${((62.5  * 16) - 1) / 16}em)`,	fetchpriority: "high" },
				{ rel: "preload",	href: this.$cloudFlare.getImage(picture.mobile.src,			{ width: picture.mobile.width,			height: picture.mobile.height }),			as: 'image', media: `(min-width: 0em) 		and (max-width: ${((43.75 * 16) - 1) / 16}em)`,	fetchpriority: "high" },
			],
			script: [
				{
					type: "application/ld+json",
					json: this.pageContent.structuredData.faqPage,
				},
			],
		}
	},
	async asyncData(context) {
		const homeFaqIds = ["10001", "10060", "90001"]
		const [pageMetaData, faqs] = await Promise.all([
			getPageMetaData(context.$strapi, "home"),
			import("~/static/data/faq").then(({ faqs }) => faqs.filter((faq) => homeFaqIds.includes(faq.id))),
			context.store.dispatch("fetchHeading"),
			context.store.dispatch("reviews/fetchCompany"),
		])

		const pageContent = {
			pageHeader: {
				title: "GO love every moment",
				headline: "Your dream holiday starts here. Book your car rental and explore New Zealand with us.",
				picture: {
					alt: "Image of a girl sitting in the backseat with her headphones on looking outside the car window and enjoying her journey with GO Rentals",
					mobile: { src: "/images/Home_Hero_Mobile.jpg", width: 375, height: 495 },
					tablet: { src: "/images/Home_Hero_Tablet.jpg", width: 720, height: 720 },
					tabletWide: { src: "/images/Home_Hero_Tablet_Wide.jpg", width: 976, height: 696 },
					desktop: { src: "/images/Home_Hero_Desktop.jpg", width: 1384, height: 618 },
					desktopWide: { src: "/images/Home_Hero_Desktop_Wide.jpg", width: 1864, height: 618 },
				},
			}
		}

		if	(context.store.state.heading?.enabled) {
			const { title, headline, headlineLink, picture } = context.store.getters.headingPageHeader
			pageContent.pageHeader.title		= title
			pageContent.pageHeader.headline		= headline
			pageContent.pageHeader.headlineLink	= headlineLink
			pageContent.pageHeader.picture		= picture
		}

		pageContent.faqs = faqs
		pageContent.structuredData = {
			faqPage: getSchemaFaqPage(faqs),
		}

		const { title, description } = pageMetaData ?? {
			title: "GO Rentals - Car Rental & Car Hire New Zealand",
			description: "Looking for affordable car rental in New Zealand? Choose GO Rentals, one of the country's top hire car companies. Book your New Zealand car rental today!"
		}
		const pageMeta = {
			title,
			description
		}

		return {
			pageMeta,
			pageContent
		}
	},
}
</script>
